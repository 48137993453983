import { UniformColoring } from './UniformColoring';
import LiteMol from 'litemol/dist/js/LiteMol-plugin.js';
import { baseAxiosInstance } from '../../utils/services/BaseAxiosInstance.service';
import { THREE } from '../../utils/constants/arViewConstants';

export function createMoleculeModel(
    params,
    molecule,
    visualControlMode
) {
    var moleculeModel = new MoleculeModel();
    moleculeModel.molecule = molecule;
    moleculeModel.cifPath = params.cifPath;
    moleculeModel.params = params;
    molecule.centerObject.add(moleculeModel.threeModel);

    baseAxiosInstance.get(moleculeModel.cifPath).then(response => {
        var cifText = response.data;
        // parse cif text
        var parseComp =
            LiteMol.Core.Formats.Molecule.SupportedFormats.mmCIF.parse(cifText);
        parseComp.run().then(out => {
            moleculeModel.lmcModel = out.result;
            var uniformTheme = LiteMol.Visualization.Theme.createUniform();

            // create the whole model for the molecule
            var wholeModelComp =
                LiteMol.Visualization.Molecule.Cartoons.Model.create(null, {
                    atomIndices:
                        moleculeModel.lmcModel.models[0].data.atoms.indices,
                    model: moleculeModel.lmcModel.models[0],
                    params: LiteMol.Visualization.Molecule.Cartoons
                        .DefaultCartoonsModelParameters,
                    props: null,
                    queryContext: moleculeModel.lmcModel.models[0].queryContext,
                    theme: uniformTheme
                });

            wholeModelComp.run().then(wmodel => {
                var whole = wmodel;

                var data = moleculeModel.lmcModel.models[0].data;
                // loop through all chains in the molecule
                for (var i = 0; i < data.chains.count; i++) {
                    var color = params.colorScheme.getChainColor(i);

                    var rc = new UniformColoring(color);
                    var mapping =
                        LiteMol.Visualization.Theme.createPalleteIndexMapping(
                            rc.getColorIndex,
                            rc.colors
                        );
                    var theme =
                        LiteMol.Visualization.Theme.createMapping(mapping);

                    // get the params of the molecule at the chain index
                    let modelParams = {
                        atomIndices: indexArray(
                            data.chains.atomStartIndex[i],
                            data.chains.atomEndIndex[i]
                        ),
                        model: moleculeModel.lmcModel.models[0],
                        props: null,
                        queryContext:
                            moleculeModel.lmcModel.models[0].queryContext,
                        theme: theme
                    };

                    let modelComp = null;
                    // for space filling view, use VDW ball params
                    if (visualControlMode === 'SpaceFilling') {
                        // params are based off of private method createVDWBallsParams() in LiteMol.Bootstrap.Visualization.Molecule
                        modelParams.params = {
                            bondRadius: 0,
                            hideBonds: true,
                            type: 'VDWBalls',
                            atomRadius:
                                LiteMol.Bootstrap.Utils.vdwRadiusFromElementSymbol(
                                    modelParams.model
                                )
                        };
                        modelComp =
                            LiteMol.Visualization.Molecule.BallsAndSticks.Model.create(
                                null,
                                modelParams
                            );
                    }
                    // for ribbon view, use cartoon params
                    else {
                        modelParams.params =
                            LiteMol.Visualization.Molecule.Cartoons.DefaultCartoonsModelParameters;
                        modelComp =
                            LiteMol.Visualization.Molecule.Cartoons.Model.create(
                                null,
                                modelParams
                            );
                    }
                    modelComp.run().then(model => {
                        addToParentModel(moleculeModel, model, whole, params);
                    });
                }
            });
        });
    });
    return moleculeModel;
}

function addToParentModel(model, chain, whole, params) {
    // Center the molecule in the parent.
    var world = new THREE.Matrix4();
    world.makeScale(1, 1, 1);

    var c = new THREE.Matrix4();
    c.makeTranslation(-whole.centroid.x, -whole.centroid.y, -whole.centroid.z);

    var t = new THREE.Matrix4();
    t.makeTranslation(
        params.translation.x,
        params.translation.y,
        params.translation.z
    );

    var r = new THREE.Matrix4();
    var e = new THREE.Euler(
        params.rotation.x,
        params.rotation.y,
        params.rotation.z,
        'XYZ'
    );
    r.makeRotationFromEuler(e);

    var s = new THREE.Matrix4();
    s.makeScale(params.scale.x, params.scale.y, params.scale.z);

    world.multiply(t);
    world.multiply(r);
    world.multiply(s);
    world.multiply(c);
    chain.object.applyMatrix(world);

    // Add model to parent and set isLoaded
    model.threeModel.add(chain.object);
}

function MoleculeModel() {
    this.molecule = null;

    this.isLoaded = false;
    this.cifPath = null;
    this.params = null;

    this.lmcModel = null;
    this.lmvModel = null;
    this.threeModel = new THREE.Object3D();
    this.threeModel.visible = false;

    this.update = function (dt) {};
    this.render = function (dt) {};
    this.setVisible = function (visible) {
        this.threeModel.visible = visible;
    };
}

function indexArray(start, end) {
    var array = [];
    for (var i = start; i < end; i++) {
        array.push(i);
    }
    return array;
}
