import React, { ChangeEvent } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField, Typography} from '@mui/material';
import { MoleculeTypes } from '../../utils/types';
import EnvironmentalFactorTrigger from './EnvironmentalFactorTrigger';

function MoleculeState(props: MoleculeTypes.MoleculeStateInput) {
    function fileUpload(selectorFiles: FileList | null) {
        if (selectorFiles == null) return;
        // Save the file in our state
        props.updateState(props.id, {
            ...props.stateParameters,
            cifFile: selectorFiles[0]
        });
    }

    const textFieldEntered = (e: ChangeEvent<HTMLInputElement>) => {
        let state = { ...props.stateParameters };
        state[e.target.name] = e.target.value;
        props.updateState(props.id, state);
    }

    function deleteState() {props.deleteState(props.id);}

    function deleteEnvironmentalFactorTrigger(index: number) {props.deleteEnvironmentalFactorTrigger(props.id, index);}

    function updateEnvironmentalFactorTriggerState(id: number, factorTrigger: MoleculeTypes.EnvironmentalFactorTriggerInterface) {
        let state = { ...props.stateParameters };
        state['environmentalFactorTriggers'][id] = factorTrigger;
        props.updateState(props.id, state);
    }

    return (
        <Accordion sx={ {width: '100%'} }>
            <AccordionSummary expandIcon={ <ExpandMoreIcon/> } aria-controls="panel1a-content" id="panel1a-header">
                {
                    props.id === 0 ? <Typography sx={{fontWeight: '500', fontSize: 16}}> { "Base State" } </Typography>
                        : <Typography sx={{fontWeight: '500', fontSize: 16}}> { "State " + props.id } </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                {/* File Upload button */ }
                <Box className="stateButtons" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Button sx={{mr: 1}} variant="contained" component="label">
                        Upload .cif File
                        <input type="file" onChange={ (e) => fileUpload(e.target.files) } accept=".cif" hidden/>
                    </Button>
                    <Typography>{ props.stateParameters.cifFile?.name.split('T')[0] }</Typography>
                </Box>

                <TableContainer sx={{mb: 1}}>
                    <Table sx={{maxWidth: '90vw', overflow: 'auto'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Parameter</TableCell>
                                <TableCell align="center">X</TableCell>
                                <TableCell align="center">Y</TableCell>
                                <TableCell align="center">Z</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Position</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="posX"
                                               onChange={ textFieldEntered } value={ props.stateParameters.posX }
                                               type="number"/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="posY"
                                               onChange={ textFieldEntered } value={ props.stateParameters.posY }
                                               type="number"/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="posZ"
                                               onChange={ textFieldEntered } value={ props.stateParameters.posZ }
                                               type="number"/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Rotation</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotX"
                                               onChange={ textFieldEntered } value={ props.stateParameters.rotX }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotY"
                                               onChange={ textFieldEntered } value={ props.stateParameters.rotY }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="rotZ"
                                               onChange={ textFieldEntered } value={ props.stateParameters.rotZ }
                                               type="number" sx={ {maxWidth: 100} }/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Model Scale</TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleX" onChange={ textFieldEntered }
                                               value={ props.stateParameters.scaleX } type="number"
                                               sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleY" onChange={ textFieldEntered }
                                               value={ props.stateParameters.scaleY } type="number"
                                               sx={ {maxWidth: 100} }/></TableCell>
                                <TableCell>
                                    <TextField id="filled-basic" variant="standard" name="scaleZ" onChange={ textFieldEntered }
                                               value={ props.stateParameters.scaleZ } type="number"
                                               sx={ {maxWidth: 100} }/></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Extra Info For States other than the base state */ }
                { props.id !== 0 &&
                    <Grid container direction = "column" justifyContent = "flex-start" alignItems = "stretch">
                        {/* Environmental Factor Triggers */ }
                        <Grid item>
                            <Typography sx={{fontWeight: '500', fontSize: 16, mb: 1}}>Switch to { "State " + props.id } when: </Typography>
                            { props.stateParameters.environmentalFactorTriggers.map((factor, index) => (
                                <EnvironmentalFactorTrigger key={ index } id={ index } factorTrigger={ factor } environmentalFactorList={ props.environmentalFactorList }
                                                            updateState={ updateEnvironmentalFactorTriggerState } deleteSelf={ deleteEnvironmentalFactorTrigger }/>
                            )) }
                        </Grid>
                        <Box className="stateButtons" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'}}>
                            {/* Add Trigger Button */ }
                            <Button variant="contained" sx={{mb: 1}} onClick={ () => (props.addEnvironmentalFactorTrigger(props.id)) }>
                                Add Trigger
                            </Button>
                            {/* Delete State Button */ }
                            <Button variant="contained" sx={{mb: 1}} color="error" onClick={ deleteState }>
                                Delete State
                            </Button>
                        </Box>
                    </Grid>
                }
            </AccordionDetails>
        </Accordion>
    );
}

export default MoleculeState;
