import {THREE} from '../../utils/constants/arViewConstants';
import { ArMarkerControls } from '@ar-js-org/ar.js/three.js/build/ar-threex.js';

export function ARMarker(id, name, path, arContext){
    this.id = id;

    // Store the name and path
    this.name = name;
    this.path = path;

    // Create new object to track location
    this.object = new THREE.Object3D();

    // Create marker controls in context
    this.markerControls = new ArMarkerControls(arContext, this.object, {
        type : 'pattern',
        patternUrl: path,
        // Update the modelViewMatrix of object based on perceived orientation
        changeMatrixMode: 'modelViewMatrix'
    });
}
