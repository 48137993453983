import {Box, Typography} from "@mui/material";
import React from "react";
import {ADD_MOLECULE} from "../../utils/constants/moleculeConstants";

export function MoleculeImage( props: {moleculeId: number, tagImage}) {
    if ( props.moleculeId === ADD_MOLECULE || props.tagImage == null ) return null;
    return (
        <Box sx={{mb: 1}}>
            <Box>
                <Typography sx={{fontWeight: '500', fontSize: 20}}>Marker Image</Typography>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box sx={{maxWidth: '200px', maxHeight: '200px'}} component='img' alt='Image not Available' src={`data:image/png;base64,${props.tagImage}`} />
            </Box>
        </Box>
    )
}
export default MoleculeImage;