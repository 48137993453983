import React, {ChangeEvent, useEffect, useState} from 'react';
import '../../App.css';
import FormControl from '@mui/material/FormControl';
import {Box, IconButton, TextField, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoleculeState from "./MoleculeState";
import {postMolecule, putMolecule, getMolecule, deleteMolecule} from "../../utils/helpers/moleculeHelper";
import {MoleculeData, MoleculeStateParameters} from "../../utils/types/moleculeTypes";
import {useSnackbar, VariantType} from 'notistack';
import MoleculeImage from "./MoleculeImage";
import {ADD_MOLECULE, DEFAULT_FACTOR_TRIGGER, DEFAULT_MOLECULE_DATA, DEFAULT_MOLECULE_STATE} from "../../utils/constants/moleculeConstants";
import FormLabel from "../utils/FormLabel";
import Toolbar from "../utils/Toolbar";
import SubmitButton from "../utils/SubmitButton";
import {getEnvironmentalFactors as getFactors} from "../../utils/helpers/environmentalFactorsHelper";
import {EnvironmentalFactor} from "../../utils/types/environmentalFactorTypes";

function MoleculeDetails(props: {moleculeId: number, updateMolecule, setDirtyBit}) {
    const [moleculeData, setMoleculeData] = useState<MoleculeData>(DEFAULT_MOLECULE_DATA());
    const [moleculeStates, setMoleculeStates] = useState<MoleculeStateParameters[]>([DEFAULT_MOLECULE_STATE()]);
    const [environmentalFactors, setEnvironmentalFactors] = useState<EnvironmentalFactor[]>([]);
    const [tagImage, setTagImage] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    function handleClickVariant(message: String, variant: VariantType) {enqueueSnackbar(message, { variant });}

    const updateMoleculeData = (e: ChangeEvent<HTMLInputElement>) => {
        let data = {...moleculeData};
        data[e.target.name] = e.target.value;
        setMoleculeData(data);
        props.setDirtyBit(true);
    }

    function addState() {
        let states = moleculeStates.slice();
        states.push(DEFAULT_MOLECULE_STATE([DEFAULT_FACTOR_TRIGGER()]));
        setMoleculeStates(states);
        props.setDirtyBit(true);
    }

    function updateMoleculeState(index: number, moleculeState: MoleculeStateParameters) {
        let states = moleculeStates.slice();
        states[index] = moleculeState;
        setMoleculeStates(states);
        props.setDirtyBit(true);
    }

    function deleteMoleculeState(index: number) {
        let states = moleculeStates.slice();
        states.splice(index, 1);
        setMoleculeStates(states);
    }

    function addEnvironmentalFactorTrigger(stateId: number) {
        let states = moleculeStates.slice();
        let stateOfInterest = states[stateId];
        stateOfInterest.environmentalFactorTriggers.push(DEFAULT_FACTOR_TRIGGER());
        states[stateId] = stateOfInterest;
        setMoleculeStates(states);
    }

    function deleteEnvironmentalFactorTrigger(stateId: number, index: number) {
        let states = moleculeStates.slice();
        let stateOfInterest = states[stateId];
        stateOfInterest.environmentalFactorTriggers.splice(index, 1);
        states[stateId] = stateOfInterest;
        setMoleculeStates(states);
    }

    function getEnvironmentalFactors() {
        getFactors().then((factors: EnvironmentalFactor[]) => {
            setEnvironmentalFactors(factors);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function addMolecule() {
        postMolecule( moleculeStates, moleculeData ).then((newMoleculeId: number) => {
            handleClickVariant("Molecule Successfully Added", 'success');
            props.updateMolecule( newMoleculeId );
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function editMolecule() {
        putMolecule(props.moleculeId, moleculeStates, moleculeData).then(() =>{
            handleClickVariant("Molecule Successfully Saved", 'success');
            props.updateMolecule( props.moleculeId );
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function getMoleculeData() {
        getMolecule(props.moleculeId).then((molecule) => {
            setMoleculeData({name: molecule.name, description: molecule.description});
            setMoleculeStates(molecule.moleculeStates);
            setTagImage(molecule.moleculeTagImage);
        }).catch((error) => {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    function removeMolecule() {
        deleteMolecule( props.moleculeId ).then(() => {
            props.updateMolecule();
            handleClickVariant("Molecule Successfully Deleted", 'success');
        }).catch(function (error) {
            handleClickVariant(error.response.data.message, 'error');
        });
    }

    useEffect(() => {
        getEnvironmentalFactors();
        if ( props.moleculeId === ADD_MOLECULE ) {
            setMoleculeData( DEFAULT_MOLECULE_DATA());
            setMoleculeStates([DEFAULT_MOLECULE_STATE()]);
        } else {
            getMoleculeData();
        }
        props.setDirtyBit(false);
    }, [props.moleculeId]);

    return (
        <Box sx={{flexBasis: '600px', p: 2}}>
            <FormLabel id={props.moleculeId} message={'Molecule'}/>
            <FormControl>
                <Box sx={{display: 'flex', mb: 2}}>
                    <Box className="flexColumn" sx={{width: '100%'}}>
                        <TextField required label="Name" name="name" variant="standard"
                                   onChange={updateMoleculeData} value={moleculeData.name} />
                        <TextField label="Description" variant="standard" name="description"
                                   onChange={updateMoleculeData} value={moleculeData.description} />
                    </Box>
                    <Toolbar delete={removeMolecule} edit={editMolecule} id={props.moleculeId}
                             message={'Contact an administrator to restore this molecule.'}/>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                    <Typography sx={{fontWeight: '500', fontSize: 20}}>Molecule States</Typography>
                    <IconButton aria-label="add" color="primary" onClick={addState}><AddIcon/></IconButton>
                </Box>
                <Box className="flexColumn" sx={{justifyContent: 'flex-start', mb: 1}}>
                    {/* Dropdown panel for base state of molecule*/}
                    {moleculeStates.map((state, index) => (
                        <MoleculeState key={index} id={index} stateParameters={state} updateState={updateMoleculeState}
                                       deleteState={deleteMoleculeState} environmentalFactorList={environmentalFactors}
                                       addEnvironmentalFactorTrigger={addEnvironmentalFactorTrigger}
                                       deleteEnvironmentalFactorTrigger={deleteEnvironmentalFactorTrigger} />))}
                </Box>
                <MoleculeImage moleculeId={props.moleculeId} tagImage={tagImage} />
                <SubmitButton id={props.moleculeId} submitHandler={addMolecule} message={'Submit new Molecule'} />
            </FormControl>
        </Box>
    );
}

export default MoleculeDetails;