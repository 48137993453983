import {Molecule, MoleculeData, MoleculeStateParameters} from "../types/moleculeTypes";
import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {IMAGE_URL, MOLECULE_URL} from "../constants/moleculeConstants";

function formatDataForAPI(moleculeStates: MoleculeStateParameters[],
                                 moleculeData: MoleculeData) {
    // New Molecule Object
    let newMolecule = { id: 1,
                        name: moleculeData.name,
                        active: true,
                        description: moleculeData.description == null ? "" : moleculeData.description,
                        defaultStateId: 0,
                        verticalOffset: 1.5,
                        markerId: 1,
                        baseModels: [],
                        colorSchemes: [],
                        coloredModels: [],
                        states: [],
                        factorStateTriggers: [],
                        arFactorTriggers: []
    };

    let files = [];
    moleculeStates.forEach( function( currState, i ) {
        // add a baseModel, new state, and color scheme
        newMolecule.baseModels.push( createBaseModel( currState, i ) );
        newMolecule.states.push( createState( i ) );
        makeColorScheme( newMolecule, currState, i );

        if ( i > 0 ) {
            // each factorStateTrigger corresponds to one state
            let newFactorStateTrigger = {id:                i,
                                        name:               "t" + i,
                                        toStateId:          i,
                                        useActive:          false,
                                        activeExclusive:    false,
                                        activeConditions:   [],
                                        rangeConditions:    []};

            currState.environmentalFactorTriggers.forEach( function(trigger){
                if ( trigger.useActive ) {
                    // newFactorStateTrigger.useActive = true;
                    // newFactorStateTrigger.activeConditions.push(newActiveCondition(trigger));
                } else {
                    newFactorStateTrigger.rangeConditions.push(newRangeCondition(trigger));
                }
            });
            newMolecule.factorStateTriggers.push( newFactorStateTrigger );
        }
        files.push(currState.cifFile);
    });
    // Construct form data
    let fd = new FormData();
    fd.append('dto', new Blob([JSON.stringify(newMolecule)], {type:'application/json'}));
    for (let i = 0; i < files.length; i++) { fd.append('files', files[i]); }
    return fd;
}

async function formatData(rawData: any) {
    let moleculeData = {name: rawData.name, description: rawData.description, moleculeStates: [], moleculeTagImage: null};
    let states = rawData.states.sort(function(a,b) { return a.id - b.id});
    if ( moleculeData.description == null ) moleculeData.description = "";

    for (let i = 0; i < states.length; i++) {
        let stateId = states[i].id;

        // Base Model
        let baseModel = null;
        for (let j = 0; j < rawData.baseModels.length; j++) {
            if (rawData.baseModels[j].id === stateId) {
                baseModel = rawData.baseModels[j];
                break;
            }
        }

        // Environmental Factor Triggers
        let rangeConditions = null;
        for (let j = 0; j < rawData.factorStateTriggers.length; j++) {
            if (rawData.factorStateTriggers[j].toStateId === stateId) {
                rangeConditions = rawData.factorStateTriggers[j].rangeConditions;
            }
        }

        // Construct the EnvironmentalFactorTriggerInterfaces
        let envFactorTriggers = []
        if (rangeConditions != null) {
            rangeConditions.forEach( function(condition) {
                let trigger = {
                    environmentalFactorId: condition.factorId,
                    useActive: false,
                    activeConditionValue: false,
                    rangeCondition: {
                        type: condition.type,
                        minValue: condition.minValue,
                        maxValue: condition.maxValue
                    }
                }
                envFactorTriggers.push(trigger)
            })
        }

        // Get cif file
        let modelFile = null;
        await baseAxiosInstance.get(baseModel.cifPath, {responseType: "blob"})
            .then(function (response) {
                modelFile = new File([response.data], baseModel.cifPath.substring(5), {type: "chemical/x-cif"});
            })

        let stateData = {
            posX: baseModel.transX, posY: baseModel.transY, posZ: baseModel.transZ,
            rotX: baseModel.rotX, rotY: baseModel.rotY, rotZ: baseModel.rotZ,
            scaleX: baseModel.scaleX, scaleY: baseModel.scaleY, scaleZ: baseModel.scaleZ,
            cifFile: modelFile,
            environmentalFactorTriggers: envFactorTriggers
        }
        moleculeData.moleculeStates.push(stateData);
    }
    return moleculeData;
}

function createBaseModel( currState: MoleculeStateParameters, stateId: number ) {
    return {
        id: stateId,
        name: "State" + stateId,  // Could add field for state names on the UI
        cifPath: null,
        transX: currState.posX, transY: currState.posY, transZ: currState.posZ,
        rotX: currState.rotX, rotY: currState.rotY, rotZ: currState.rotZ,
        scaleX: currState.scaleX, scaleY: currState.scaleY, scaleZ: currState.scaleZ,
    };
}

function createState( stateId: number ) {
    return {
        id: stateId,
        name: "State" + stateId,
        coloredModelId: stateId
    };
}

function makeColorScheme( newMolecule, currState, stateId: number ) {

    let color0;
    let color1;
    let color2;
    let color3;

    if (stateId % 2) {
        color0 = "#ff6666";
        color1 = "#66ff66";
        color2 = "#6666ff";
        color3 = "#ff66ff";
    } else {
        color0 = "#ff3333";
        color1 = "#33ff33";
        color2 = "#3333ff";
        color3 = "#ff33ff";
    }
    
    let newScheme = {
        id: stateId,
        name: "State" + stateId,
        chainColors: [],
        defaultColor: "#99ff99",
        defaultStyle: "BallsAndSticks",
        description: null
    };

    let newChain0 = {
        chain_index:0,
        color: color0,
        style: "BallsAndSticks",
        description: null,
    }
    let newChain1 = {
        chain_index:1,
        color: color1,
        style: "BallsAndSticks",
        description: null,
    }
    let newChain2 = {
        chain_index:2,
        color: color2,
        style: "BallsAndSticks",
        description: null,
    }
    let newChain3 = {
        chain_index:3,
        color: color3,
        style: "BallsAndSticks",
        description: null,
    }
    
    newScheme.chainColors.push(newChain0);
    newScheme.chainColors.push(newChain1);    
    newScheme.chainColors.push(newChain2);
    newScheme.chainColors.push(newChain3);

    // add a colored model that uses the base model and color scheme
    let newColoredModel = {
        id: stateId,
        name: "Model" + stateId,
        baseModelId: stateId,
        colorSchemeId: newScheme.id
    };

    newMolecule.coloredModels.push( newColoredModel );

    newMolecule.colorSchemes.push( newScheme );
}

function newRangeCondition( condition ) {
    return {
        id: null,
        type: condition.rangeCondition.type,
        factorId: condition.environmentalFactorId,
        minValue: condition.rangeCondition.minValue,
        maxValue: condition.rangeCondition.maxValue
    };
}

export async function postMolecule( moleculeStates: MoleculeStateParameters[], moleculeData: MoleculeData) {
    let newMoleculeId;
    await baseAxiosInstance.post( MOLECULE_URL, formatDataForAPI(moleculeStates, moleculeData)).then((response) => {
        newMoleculeId = response.data;
    }).catch((error) => {throw error});
    return newMoleculeId;
}

export async function putMolecule( moleculeId: number, moleculeStates: MoleculeStateParameters[], moleculeData: MoleculeData) {
    let newMoleculeId;
    await baseAxiosInstance.put( MOLECULE_URL + "/" + moleculeId, formatDataForAPI(moleculeStates, moleculeData)).then((response) => {
        newMoleculeId = response.data;
    }).catch((error) => {throw error});
    return newMoleculeId;
}

export async function getMolecule( moleculeId: number) {
    let molecule;
    await baseAxiosInstance.get( MOLECULE_URL + "/" + moleculeId).then(async (response) => {
        await formatData(response.data).then(async (formattedMolecule) => {
            molecule = formattedMolecule;
            await getMoleculeTagImage(moleculeId).then((moleculeTagImage) => {
                molecule.moleculeTagImage = moleculeTagImage;
            }).catch((error) => {throw error});
        }).catch((error) => {throw error});
    }).catch((error) => {throw error});
    return molecule;
}

export async function getMolecules() {
    let molecules: Molecule[];
    await baseAxiosInstance.get('molecules').then((response) => {
        molecules = response.data
    }).catch((error) => {throw error});
    return molecules;
}

export async function deleteMolecule( moleculeId: number ) {
    await baseAxiosInstance.delete( MOLECULE_URL + "/" + moleculeId).catch((error) => {throw error});
}

export async function getMoleculeTagImage( moleculeId: number) {
    let moleculeTagImage;
    await baseAxiosInstance.get(MOLECULE_URL + "/" + moleculeId + IMAGE_URL).then(function (response) {
        moleculeTagImage = response.data;
    }).catch((error) => {throw error});
    return moleculeTagImage;
}