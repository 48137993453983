import {
    ArToolkitContext,
    ArToolkitSource
} from '@ar-js-org/ar.js/three.js/build/ar-threex.js';
import { baseAxiosInstance } from '../../utils/services/BaseAxiosInstance.service';
import { MoleculeLibrary } from '../js/MoleculeLibrary';
import { ARMarker } from '../js/ARMarker';
import { THREE } from '../../utils/constants/arViewConstants';
import { Molecule } from '../js/Molecule';

export function ARApp() {
    // Settings variables
    this.visualControlMode = 'Ribbon';

    // THREE Js Rendering Variables
    this.renderer = null;
    this.scene = null;
    this.camera = null;

    // Scene Lighting
    this.directionalLight = null;
    this.ambientLight = null;

    // AR.js camera source and context
    this.arToolkitSource = null;
    this.arToolkitContext = null;

    // Tracked AR Markers
    this.arMarkers = [];

    // MoleculeLibrary and current active Molecule
    this.moleculeLibrary = new MoleculeLibrary(this);
    this.activeMolecule = null;

    this.init = function (mountRef) {
        // Initialize Renderer And Scene
        this.initRenderer(mountRef);
        this.initScene();

        // Initialize AR Variables
        this.initARSource();
        this.initARContext();

        // Load AR Markers
        this.loadARMarkersAndMolecules();
    };

    this.run = function () {
        let _this = this;

        // run the rendering loop
        let lastTimeMsec = null;

        requestAnimationFrame(function animate(nowMsec) {
            // keep looping
            requestAnimationFrame(animate);

            // measure time
            lastTimeMsec = lastTimeMsec || nowMsec - 1000 / 60;
            let deltaMsec = Math.min(200, nowMsec - lastTimeMsec);
            lastTimeMsec = nowMsec;

            _this.update(deltaMsec / 1000);
            _this.render(deltaMsec / 1000);
        });
    };

    this.update = function (dt) {
        // Update the AR context if source is ready
        if (this.arToolkitSource.ready === true) {
            this.arToolkitContext.update(this.arToolkitSource.domElement);
        }

        if (this.activeMolecule != null) this.activeMolecule.update(dt);
        this.scanForARMarkers(dt);
    }


    this.render = function (dt) {
        // Render scene with renderer
        this.renderer.render(this.scene, this.camera);
    };


    this.scanForARMarkers = function (dt) {
        for (let i = 0; i < this.arMarkers.length; i++) {
            if(this.arMarkers[i].object.visible) {
                this.moleculeLibrary.molecules.forEach((value: Molecule, key: String) => {
                    if (value.rootARMarker === this.arMarkers[i]) {
                        if (value === this.activeMolecule) {
                            this.activeMolecule.update(dt);
                        } else {
                            this.changeActiveMolecule(value);
                        }
                    }
                });
            }
        }
    }

    this.getARMarkerById = function (id) {
        // Return first marker in array with id
        for (let i = 0; i < this.arMarkers.length; i++) {
            let marker = this.arMarkers[i];
            if (marker.id === id) return marker;
        }

        // If not found return null
        return null;
    };

    this.changeActiveMolecule = function (molecule) {
        // Disable current active molecule
        if (this.activeMolecule != null) this.activeMolecule.setActive(false);

        // Set new active molecule and enable it
        this.activeMolecule = molecule;
        this.activeMolecule.setActive(true);
    };

    this.updateFactor = function (id, value) {
        if (this.activeMolecule != null) {
            this.activeMolecule.updateFactor(id, value);
        }
    };

    this.initRenderer = function (mountRef) {
        // init renderer
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true
        });
        this.renderer.setClearColor(new THREE.Color('lightgrey'), 0);
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        mountRef.current.insertBefore(this.renderer.domElement, mountRef.current.firstChild);
    };

    this.initScene = function () {
        // init scene
        this.scene = new THREE.Scene();

        // Create a Camera
        this.camera = new THREE.Camera();
        this.scene.add(this.camera);

        // Add Lighting to Scene
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        this.directionalLight.position.z = 1;
        this.scene.add(this.directionalLight);

        this.ambientLight = new THREE.AmbientLight(0x808080); // soft white light
        this.scene.add(this.ambientLight);
    };

    this.initARSource = function () {
        // Create local reference to this for anonymous functions
        let _this = this;

        this.arToolkitSource = new ArToolkitSource({
            // to read from the webcam
            sourceType: 'webcam',
            sourceWidth: window.innerWidth,
            sourceHeight: window.innerHeight,
            displayWidth: window.innerWidth,
            displayHeight: window.innerHeight
        });

        this.arToolkitSource.init(function onReady() {
            _this.onResize();
        });

        // handle window resize
        window.addEventListener('resize', function () {
            _this.onResize();
        });
    };

    this.initARContext = function () {
        var _this = this;
        // Create local reference to this for anonymous functions

        // create atToolkitContext
        _this.arToolkitContext = new ArToolkitContext({
            detectionMode: 'mono'
        });

        // initialize it
        _this.arToolkitContext.init(function onCompleted() {
            // copy projection matrix to camera
            _this.camera.projectionMatrix.copy(
                _this.arToolkitContext.getProjectionMatrix()
            );
        });
    };

    this.loadARMarkersAndMolecules = function () {
        baseAxiosInstance.get('markers').then(data => {
            let markers = JSON.parse(data.request.responseText);
            for (let i = 0; i < markers.length; i++) {
                let mjson = markers[i];
                let marker = new ARMarker(
                    mjson.id,
                    mjson.name,
                    mjson.patternPath,
                    this.arToolkitContext
                );
                this.arMarkers.push(marker);
            }
            // load the molecules without selecting a particular one
            this.loadStartingMolecules(-1);
        });
    };

    this.loadStartingMolecules = function (selectedMoleculeIndex) {
        var _this = this;
        baseAxiosInstance.get('molecules').then(data => {
            if (data.request.status === 200) {
                let molecules = JSON.parse(data.request.responseText);
                for (let i = 0; i < molecules.length; i++) {
                    let mjson = molecules[i];
                    _this.moleculeLibrary.loadMolecule(
                        mjson.id,
                        mjson.name,
                        _this.visualControlMode
                    );
                }
            }
        });
    };

    this.onResize = function () {
        // Call AR toolkits onResize
        this.arToolkitSource.onResizeElement();

        // Copy new size to renderer and AR context
        this.arToolkitSource.copyElementSizeTo(this.renderer.domElement);
        if (this.arToolkitContext.arController !== null) this.arToolkitSource.copyElementSizeTo(this.arToolkitContext.arController.canvas);
    };
}
