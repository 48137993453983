import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {TagData} from "../types/environmentalFactorTagTypes";
import {FACTOR_TAG_URL, IMAGE_URL} from "../constants/environmentalFactorTagConstants";

export async function putEnvironmentalFactorTag( environmentalFactorTagData: TagData, toDelete: boolean) {
    if (toDelete) {
        environmentalFactorTagData.factorId = null;
        environmentalFactorTagData.inUse = false;
        environmentalFactorTagData.value = 0;
    } else {
        environmentalFactorTagData.inUse = true;
    }
    let fd = new FormData();
    fd.append('dto', new Blob([JSON.stringify(environmentalFactorTagData)], {type: 'application/json'}));
    await baseAxiosInstance.put(FACTOR_TAG_URL + "/" + environmentalFactorTagData.id, fd).catch((error) => { throw error });
}

export async function getEnvironmentalFactorTagImage( environmentalFactorTagData: TagData ) {
    let imageData: string = '';
    await baseAxiosInstance.get(FACTOR_TAG_URL + '/' + environmentalFactorTagData.id + '/' + IMAGE_URL).then((response) => {
        imageData = response.data;
    }).catch((error) => {throw error});
    return imageData;
}

export async function getEnvironmentalFactorTags() {
    let localArray: TagData[] = [];
    let unusedLocalArray: TagData[] = [];
    await baseAxiosInstance.get(FACTOR_TAG_URL).then((response) => {
        let count = 0;
        for (let environmentalFactorTag of response.data) {
            if (environmentalFactorTag.inUse) {
                localArray.push(environmentalFactorTag);
            } else {
                count++;
                unusedLocalArray.push(environmentalFactorTag);
            }
        }
    }).catch((error) => {throw error});
    return {localArray, unusedLocalArray};
}