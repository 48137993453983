import {baseAxiosInstance} from "../services/BaseAxiosInstance.service";
import {FACTORS_URL} from "../constants/environmentalFactorsConstants";
import {EnvironmentalFactor, EnvironmentalFactorData} from "../types/environmentalFactorTypes";

export async function postEnvironmentalFactor( factorData: EnvironmentalFactorData ) {
    let factorId: number;
    let fd = new FormData();
    fd.append('dto', new Blob([JSON.stringify(factorData)], { type: 'application/json' }));
    await baseAxiosInstance.post( FACTORS_URL, fd ).then((response) => {
        factorId = response.data;
    }).catch((error) => {throw error});
    return factorId;
}

export async function putEnvironmentalFactor( factorId: number, factorData: EnvironmentalFactorData ) {
    let newFactorId: number;
    let fd = new FormData();
    fd.append('dto', new Blob([JSON.stringify(factorData)], { type: 'application/json' }));
    await baseAxiosInstance.put( FACTORS_URL + "/" + factorId, fd ).then((response) => {
        newFactorId = response.data;
    }).catch((error) => {throw error});
    return newFactorId;
}

export async function getEnvironmentalFactor( factorId: number) {
    let factorData;
    await baseAxiosInstance.get( FACTORS_URL + "/" + factorId).then( async (response) => {
        factorData = response.data;
    }).catch((error) => {throw error});
    return factorData;
}

export async function getEnvironmentalFactors() {
    let environmentalFactors: EnvironmentalFactor[] = [];
    await baseAxiosInstance.get(FACTORS_URL).then(function(response){
        environmentalFactors = response.data;
    }).catch((error) => {throw(error)});
    return environmentalFactors;
}

export async function deleteEnvironmentalFactor( factorId: number ) {
    await baseAxiosInstance.delete( FACTORS_URL + "/" + factorId).catch((error) => {throw error});
}