import { Molecule } from './Molecule';
import { ColorScheme } from './ColorScheme';
import { createMoleculeModel } from './MoleculeModel';
import { MoleculeState } from './MoleculeState';
import { FactorStateTrigger } from './FactorStateTrigger';
import { ActiveCondition } from './ActiveCondition';
import { RangeCondition } from './RangeCondition';
import { ARMarkerFactorTrigger } from './ARMarkerFactorTrigger';
import { baseAxiosInstance } from '../../utils/services/BaseAxiosInstance.service';
import { SCALE_FACTOR } from '../../utils/constants/arViewConstants';
import { DEFAULT_ENVIRONMENTAL_FACTOR_DATA } from '../../utils/constants/environmentalFactorsConstants';

export function MoleculeLibrary(app) {
    // Reference to app
    this.app = app;

    // Array of loaded molecule names
    this.names = [];

    // Map of molecule names to molecule objects
    this.molecules = new Map();

    this.loadMolecule = function (id, alias, visualControlMode) {
        var _this = this;
        // Create empty molecule and add to library
        const mol = new Molecule(this.app);
        this.molecules.set(alias, mol);
        this.names.push(alias);

        // Send a request to get JSON file
        baseAxiosInstance.get('molecules/' + id).then(response => {
            const json = response.data;

            mol.setRootMarker(json.markerId);

            // Parse components of molecule object
            const baseModels = parseBaseModels(json);
            const colorSchemes = parseColorSchemes(json);
            const models = parseModels(
                json,
                baseModels,
                colorSchemes,
                mol,
                visualControlMode
            );
            const states = parseStates(json, models);
            const factors = parseFactors(json);
            const triggers = parseTriggers(json, states);
            const markers = parseArMarkers(json, _this.app, factors);

            // Add components to molecule
            mol.name = json.name;
            mol.models = Array.from(models.values());
            mol.states = Array.from(states.values());
            mol.factors = Array.from(factors.values());
            mol.factorStateTriggers = Array.from(triggers.values());
            mol.arMarkerFactorTriggers = Array.from(markers.values());
            mol.currentState = states.get(json.defaultStateId);
            mol.defaultState = mol.currentState;

            // Set visibility to true by default
            // Note: this does not add the molecule to the scene, just sets its internal visibility
            mol.setVisible(true);
        });

        return mol;
    };
    this.getMolecule = function (alias) {
        return this.molecules.get(alias);
    };

    function parseBaseModels(json) {
        let baseModels = new Map();
        for (let i = 0; i < json.baseModels.length; i++) {
            let baseModel = json.baseModels[i];
            baseModels.set(baseModel.id, baseModel);
        }
        return baseModels;
    }

    function parseColorSchemes(json) {
        let colorSchemes = new Map();
        for (let i = 0; i < json.colorSchemes.length; i++) {
            let cs = json.colorSchemes[i];
            let scheme = new ColorScheme();
            scheme.id = cs.id;
            scheme.name = cs.name;
            scheme.chainColors = cs.chainColors;
            scheme.defaultColor = cs.defaultColor;
            scheme.defaultStyle = cs.defaultStyle;
            colorSchemes.set(cs.id, scheme);
        }
        return colorSchemes;
    }

    function parseModels(
        json,
        baseModels,
        colorSchemes,
        molecule,
        visualControlMode
    ) {
        let models = new Map();
        for (let i = 0; i < json.coloredModels.length; i++) {
            let coloredModel = json.coloredModels[i];
            let baseModel = baseModels.get(coloredModel.baseModelId);
            let params = {
                cifPath: baseModel.cifPath,
                translation: {
                    x: baseModel.transX,
                    y: baseModel.transY,
                    z: baseModel.transZ
                },
                rotation: {
                    x: baseModel.rotX,
                    y: baseModel.rotY,
                    z: baseModel.rotZ
                },
                scale: {
                    x: (baseModel.scaleX * SCALE_FACTOR),
                    y: (baseModel.scaleY * SCALE_FACTOR),
                    z: (baseModel.scaleZ * SCALE_FACTOR)
                },
                verticalOffset: json.verticalOffset,
                colorScheme: colorSchemes.get(coloredModel.colorSchemeId)
            };
            models.set(
                coloredModel.id,
                createMoleculeModel(
                    params,
                    molecule,
                    function () {},
                    visualControlMode
                )
            );
        }
        return models;
    }

    function parseStates(json, models) {
        let states = new Map();
        for (let i = 0; i < json.states.length; i++) {
            let jsonState = json.states[i];
            let state = new MoleculeState();
            state.model = models.get(jsonState.coloredModelId);

            states.set(jsonState.id, state);
        }
        return states;
    }
 

    function parseFactors(json) {
        let factors = new Map();
        for (let i = 0; i < json.environmentalFactors.length; i++) {
            let jsonFact = json.environmentalFactors[i];
            let factor = DEFAULT_ENVIRONMENTAL_FACTOR_DATA();
            factor.id = jsonFact.id;
            factor.name = jsonFact.name;
            factor.imageData = jsonFact.imageData;
            factor.unit = jsonFact.unit;
            factor.defaultValue = jsonFact.defaultValue;
            factor.discrete = jsonFact.discrete;
            factor.maxValue = jsonFact.maxValue;
            factor.minValue = jsonFact.minValue;

            factors.set(jsonFact.id, factor);
        }
        return factors;
    }

    function parseTriggers(json, states) {
        let triggers = new Map();
        for (let i = 0; i < json.factorStateTriggers.length; i++) {
            let jsonTrigger = json.factorStateTriggers[i];

            let trigger = new FactorStateTrigger();
            trigger.toState = states.get(jsonTrigger.toStateId);
            trigger.conditions = parseConditions(jsonTrigger);

            triggers.set(jsonTrigger.id, trigger);
        }
        return triggers;
    }

    function parseConditions(json) {
        let conditions = [];

        if (json.useActive) {
            let activeIds = [];
            for (let i = 0; i < json.activeConditions.length; i++) {
                let ac = json.activeConditions[i];
                activeIds.push(ac.factorId);
            }
            let cond = new ActiveCondition();
            cond.factorIds = activeIds;
            cond.exclusive = json.activeExclusive;
            conditions.push(cond);
        }

        for (let i = 0; i < json.rangeConditions.length; i++) {
            let rc = json.rangeConditions[i];
            let cond = new RangeCondition();
            cond.factorId = rc.factorId;
            cond.high = rc.maxValue;
            cond.low = rc.minValue;
            conditions.push(cond);
        }

        return conditions;
    }

    function parseArMarkers(json, app, factorMap) {
        let markers = new Map();
        for (let i = 0; i < json.arFactorTriggers.length; i++) {
            let markerJson = json.arFactorTriggers[i];

            // Create new marker and set its factor values
            let marker = new ARMarkerFactorTrigger(app);
            marker.factorId = markerJson.factorId;
            marker.factorValue = markerJson.value;

            // Set the ar marker to track with
            marker.setARMarker(markerJson.markerId);

            // Set the image to display above marker
            //marker.setImage(markerJson.imagePath);
            factorMap.forEach((factor) => {
                if (factor.id === marker.factorId) {
                    marker.setImage(factor.imageData);
                }
            });
            

            // Add marker to array
            markers.set(markerJson.id, marker);
        }
        return markers;
    }
}
